import React, { useRef, useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ChatLog from './ChatLog';
import InputArea from './InputArea';
import LLMSwitcher from './LLMSwitcher';
import { ChatFileUploadProvider } from '../context/ChatFileUpload';
import { SelectedChatContext } from '../context/SelectedChatContext';
import { useRequest } from '../hooks/useRequest';
import { selectCanStopResponse, selectScrollFollowsResponse } from '../data/makeRequest';

type ScrollState = {
  scrollsUp: number;
};

function ChatArea() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const logRef = useRef<HTMLDivElement>(null);
  const { selectedChat: chat } = useContext(SelectedChatContext);
  const [scrollState, setScrollState] = useState<ScrollState>({
    scrollsUp: 0
  });
  const { stopScrollFollowing, startScrollFollowing } = useRequest();
  const scrollFollowsResponse = useSelector(selectScrollFollowsResponse);
  const canStopResponse = useSelector(selectCanStopResponse);

  useEffect(() => {
    if (logRef.current && wrapperRef.current && chat && scrollFollowsResponse) {
      if (scrollFollowsResponse) {
        const scrollPlace = chat.chatLog?.length > 0 ? 'end' : 'start';
        logRef.current.scrollIntoView({
          block: scrollPlace
        });
      }
    }
  }, [logRef, wrapperRef, chat, scrollFollowsResponse]);

  useEffect(() => {
    let ticking = false;
    function listenToScroll(e: any) {
      ticking = true;
      window.requestAnimationFrame(() => {
        if (ticking) {
          setScrollState(({ scrollsUp, ...scrollState }) => {
            return {
              ...scrollState,
              scrollsUp: e.deltaY < 0 ? scrollsUp + 1 : 0
            };
          });
          ticking = false;
        }
      });
    }

    if (wrapperRef?.current) {
      const chatLogBlock = wrapperRef.current;
      chatLogBlock.addEventListener('wheel', listenToScroll);

      return () => {
        try {
          chatLogBlock.removeEventListener('wheel', listenToScroll);
        } catch (e: any) {
          console.log('Error removing scroll.');
        }
      };
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (canStopResponse && scrollFollowsResponse) {
      if (scrollState.scrollsUp > 0) {
        stopScrollFollowing();
      }
    }
  }, [canStopResponse, scrollFollowsResponse, scrollState, stopScrollFollowing]);

  useEffect(() => {
    startScrollFollowing();
  }, [chat?.id, startScrollFollowing]);

  return (
    <ChatFileUploadProvider>
      <div className="chatLogWrapper" ref={wrapperRef}>
        <div ref={logRef}>
          <ChatLog />
        </div>
      </div>
      <div className="mainInputTools">
        <InputArea />
        <LLMSwitcher />
      </div>
    </ChatFileUploadProvider>
  );
}

export default ChatArea;
