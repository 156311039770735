import { createSlice } from '@reduxjs/toolkit';
import { authHeaders } from '../utils/auth';

type OnChunkDataCallback<ResponseData> = { (data: ResponseData): void | Promise<void> };

export async function streamRead<ResponseData>(
  response: Response,
  onChunkData: OnChunkDataCallback<ResponseData>
): Promise<ResponseData | null> {
  let result: ResponseData | null = null;
  const reader = response.body?.getReader();
  if (!reader) {
    throw new Error('Cannot retrieve response reader');
  }
  let chunks = '';
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    chunks += new TextDecoder().decode(value);
    // console.log("Received chunk:", chunks); // Log raw chunk for debugging
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const eomIndex = chunks.indexOf('}\n');
      if (eomIndex === -1) {
        break;
      }
      const messageJSON = chunks.slice(0, eomIndex + 1);
      chunks = chunks.slice(eomIndex + 2);
      result = JSON.parse(messageJSON) as ResponseData;
      await onChunkData(result);
    }
  }
  return result;
}

type OnMessageCallback<ResponseData> = { (message: string, responseData: ResponseData): void | Promise<void> };

export async function streamReadMessage<ResponseData extends { message: string; full_text?: boolean }>(
  response: Response,
  onMessage: OnMessageCallback<ResponseData>
) {
  let botMessage = '';
  await streamRead<ResponseData>(response, async (chunkData) => {
    botMessage =
      botMessage === 'Loading...' || chunkData.full_text ? chunkData.message : botMessage + chunkData.message;
    await onMessage(botMessage, chunkData);
  });
  return botMessage || '';
}

export let abortController: AbortController | undefined;

export function makeBaseRequest(url: string, options: RequestInit = { headers: {} }) {
  abortController = new AbortController();
  const { headers, ...fetchOptions } = options;

  return fetch(url, {
    signal: abortController?.signal,
    headers: { ...authHeaders(), ...(headers || {}) },
    ...fetchOptions
  });
}

export function makeRequest(url: string, payload: any = {}, options = {}) {
  return makeBaseRequest(url, {
    body: JSON.stringify(payload || {}),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    ...options
  });
}

type RequestStateType = {
  responseFromAPI: boolean;
  currentBotMessage: string;
  canStopResponse: boolean;
  scrollFollowsResponse: boolean;
};

const initialState: RequestStateType = {
  responseFromAPI: false,
  currentBotMessage: '',
  canStopResponse: false,
  scrollFollowsResponse: false
};

type HasRequestStateType = {
  requestState: RequestStateType;
};

export const requestSlice = createSlice({
  name: 'requestState',
  initialState,
  reducers: {
    setResponseFromAPI: (state, action) => {
      state.responseFromAPI = action.payload;
    },
    setCurrentBotMessage: (state, action) => {
      state.currentBotMessage = action.payload;
    },
    setCanStopResponse: (state, action) => {
      state.canStopResponse = action.payload;
    },
    setScrollFollowsResponse: (state, action) => {
      state.scrollFollowsResponse = action.payload;
    }
  }
});

export const selectResponseFromAPI = (state: HasRequestStateType) => state.requestState.responseFromAPI;

export const selectCurrentBotMessage = (state: HasRequestStateType) => state.requestState.currentBotMessage;

export const selectCanStopResponse = (state: HasRequestStateType) => state.requestState.canStopResponse;

export const selectScrollFollowsResponse = (status: HasRequestStateType) => status.requestState.scrollFollowsResponse;

export const { setResponseFromAPI, setCurrentBotMessage, setCanStopResponse, setScrollFollowsResponse } =
  requestSlice.actions;
